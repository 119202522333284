<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeSurveys.title') }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="survey in surveys"
        :key="survey.id"
        @click="onSelect(survey)"
        button
      >
        <ion-card-header>
          <ion-card-subtitle>
            {{ survey.project.name }} - {{ survey.project.customer.name }}
          </ion-card-subtitle>
          <ion-card-title>{{ survey.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div v-html="survey.notes"></div>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="surveys.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  toastController,
} from '@ionic/vue';

import ODNNoContent from '@c/odn-no-content.vue';

export default {
  name: 'OfflineStoreSurveys',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    ODNNoContent,
  },
  data() {
    return {
      surveys: [],
      surveyList: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    ...mapGetters('offline', ['getSection']),
  },
  async ionViewDidEnter() {
    await this.loadLocalData();
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.surveys = [];
    this.surveyList = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('surveys', ['selectSurvey']),
    async loadLocalData() {
      this.loading = true;
      try {
        const rawData = await Filesystem.readFile({
          path: 'surveys.json',
          directory: Directory.Data,
        });

        this.surveyList = JSON.parse(Base64.decode(rawData.data));
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.surveys.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.surveys = [];
      }

      const storeGroups = this.store.storeGroups.map((sg) => {
        return {
          id: sg.id,
          projectId: sg.project.id,
        };
      });

      const filteredSurveys = this.surveyList.filter((survey) => {
        // Check if this survey is in the store (groups) projects...
        if (!storeGroups.find((sg) => sg.projectId === survey.project.id)) {
          return false;
        }

        // But also in the store group if survey store group != null
        if (
          survey.storeGroup &&
          !storeGroups.find((sg) => sg.id === survey.storeGroup.id)
        ) {
          return false;
        }

        // If user role is "user", find if the user is in a same group as the survey
        if (this.profile.role.name === 'user' && survey.groups) {
          if (
            !survey.groups.find((sg) => this.profile.groups.includes(sg.id))
          ) {
            return false;
          }
        }

        return true;
      });

      this.surveys = filteredSurveys
        .filter((s) => {
          const search = this.searchText.toLowerCase();
          return (
            s.name.toLowerCase().indexOf(search) > -1 ||
            s.project.name.toLowerCase().indexOf(search) > -1 ||
            s.project.customer.name.toLowerCase().indexOf(search) > -1
          );
        })
        .slice(this.page * this.limit, this.page * this.limit + this.limit);

      if (this.surveys.length < filteredSurveys) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
    onSelect(survey) {
      this.selectSurvey(survey);
      this.$router.push(
        `/offline/stores/${this.$route.params.id}/surveys/${survey.id}`
      );
    },
  },
};
</script>
